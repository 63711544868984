export default class HomeService {
    constructor() {
        if ($('.home').length > 0) {
            if (!IRISCollectionCustomer.isEcomodeActive) {
                import(
                    '@scripts/components/front-page/slider-home.component' /* webpackChunkName: "scripts/slider-home.component" */
                ).then(({ default: SliderHomeComponent }) => {
                    new SliderHomeComponent()
                })

                import(
                    '@scripts/services/animation.service' /* webpackChunkName: "scripts/animation.service" */
                ).then(({ default: AnimationService }) => {
                    new AnimationService()
                })

                if (
                    !('ontouchstart' in window) &&
                    window.matchMedia('(min-width: 1000.1px)').matches
                ) {
                    import(
                        '@scripts/services/smoothScroll.service' /* webpackChunkName: "scripts/smoothScroll.service" */
                    ).then(({ default: SmoothScroll }) => {
                        new SmoothScroll()
                    })
                }

                if (window.matchMedia('(min-width: 999px)').matches) {
                    import(
                        '@scripts/components/front-page/strate-accelerator.component' /* webpackChunkName: "scripts/strate-accelerator.component" */
                    ).then(({ default: StrateAcceleratorComponent }) => {
                        new StrateAcceleratorComponent()
                    })
                }
            }
        }
    }
}
